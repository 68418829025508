import { Layout } from "antd";
import { Navigate } from "react-router-dom";
import { AuthenticationService } from "../../services/authenticationService";
import AppHeader from "../AppHeader/AppHeader";
import SideMenu from "../SideMenu/SideMenu";

const ProtectedRoute = ({ component: Component }) => {
  const authenticationService = new AuthenticationService();
  return authenticationService.isAuthenticated() ? (
    <Layout
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <SideMenu />
      <div className="main-container">
        <AppHeader />
        <Layout.Content>
          <div
            style={{
              border: "1px solid #f0f0f0",
              height: "100%",
              width: "100%",
            }}
          >
            <Component />
          </div>
        </Layout.Content>
      </div>
    </Layout>
  ) : (
    <Navigate to={"/login"} />
  );
};

export default ProtectedRoute;
