import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown } from "antd";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import useHeaderSwitch from "../../hooks/useHeaderSwitch";
import { AuthenticationService } from "../../services/authenticationService";

const UserDropdownMenu = (props: {
  isFocused: boolean;
  onMouseEnter?: () => void;
}) => {
  const { isHeaderVisible, toggleHeader } = useHeaderSwitch();
  const authenticationService = new AuthenticationService();

  const userDivContainerMenu = (originNode: ReactNode) => {
    return (
      <div className="profileDropdown">
        <h3>{authenticationService.getUsername()}</h3>
        <div className="profile-menu-item" onClick={() => toggleHeader()}>
          <Link to="/">
            <SettingOutlined />
            <span style={{ marginLeft: "10px" }}>Switch header view</span>
          </Link>
        </div>
        <div className="profile-menu-item">
          <Link to="/logout">
            <LogoutOutlined />
            <span style={{ marginLeft: "10px" }}>Logout</span>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <Dropdown
      dropdownRender={userDivContainerMenu}
      trigger={["click"]}
      getPopupContainer={(triggerNode) => {
        const result =
          triggerNode.parentElement?.parentElement?.parentElement?.parentElement
            ?.parentElement;
        return !!result ? result : triggerNode;
      }}
    >
      <div className="profile-name">
        <div className="icon-container" onMouseEnter={props.onMouseEnter}>
          <UserOutlined
            style={{
              fontSize: "20px",
            }}
          />
        </div>
        {(isHeaderVisible || props.isFocused) && (
          <>
            <h3
              style={{
                paddingRight: "5px",
                paddingLeft: "5px",
                fontWeight: "bold",
              }}
            >
              {authenticationService.getUsername()}
            </h3>
            <span
              className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom m-l-1"
              aria-hidden="true"
              role="none"
              data-testid="chevron-up-icon"
            >
              <svg
                width="16"
                height="16"
                fill="currentColor"
                focusable="false"
                viewBox="0 0 24 24"
              >
                <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
              </svg>
            </span>
          </>
        )}
      </div>
    </Dropdown>
  );
};

export default UserDropdownMenu;
