import { lazy, Suspense } from "react";
import Loading from "./../Loading/index";

export const DefaultLoadable = (component: () => Promise<any>) => {
  const LazyComponent = lazy(component);

  return (props: any) => (
    <Suspense fallback={<Loading />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};
