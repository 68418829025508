import { Navigate, Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import { DefaultLoadable } from "./components/DefaultLoadable/index";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import { QueryClientProvider } from "./react-query/QueryClientProvider";
import { Routes as ToolRoutes } from "./routes";
import { AuthenticationService } from "./services/authenticationService";

const App = () => {
  const authenticationService = new AuthenticationService();
  let routes = new ToolRoutes().getRoutes();

  const firstRoute = authenticationService.isAuthenticated()
    ? "/campaigns"
    : "/login";

  return (
    <BrowserRouter>
      <QueryClientProvider>
        <Routes>
          <Route
            key={"default"}
            path={"/"}
            Component={() => <Navigate to={firstRoute} />}
          />
          <Route
            key={"login"}
            path={"/login"}
            Component={DefaultLoadable(() => import("./scenes/Login/Login"))}
          />
          <Route
            key={"campaign-report"}
            path={"/campaignreports/:campaignId/:reportId"}
            Component={DefaultLoadable(
              () => import("./scenes/ReportCreator/ReportContainer")
            )}
          />
          <Route
            key={"strategyHtml"}
            path={"/campaign/:campaignId/strategy/:strategyId"}
            Component={DefaultLoadable(
              () => import("./scenes/Strategy/StrategyHtmlEditor")
            )}
          />
          <Route
            key={"strategy"}
            path={"/campaign/:campaignId/strategynew/:strategyId"}
            Component={DefaultLoadable(
              () => import("./scenes/Strategy/StrategyEditor")
            )}
          />
          {routes.map((route) => (
            <Route
              key={route.title}
              path={route.path}
              Component={() => {
                document.title = route.title;
                return <ProtectedRoute component={route.component} />;
              }}
            />
          ))}
          <Route
            key={"first-route"}
            Component={() => {
              return <Navigate to={firstRoute} />;
            }}
          />
        </Routes>
        <ToastContainer />
      </QueryClientProvider>
    </BrowserRouter>
  );
};
export default App;
