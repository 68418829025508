import "./app-header-sidebar.scss";
import logoImageSVG from "./logo_simple.svg";

const AppHeaderSideBar = () => {
  return (
    <div className="nav-sidebar-brand">
      <img src={logoImageSVG} alt="CRM4" />
    </div>
  );
};

export default AppHeaderSideBar;
