import {
  QueryClient,
  QueryClientProvider as _ReactQueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactNode } from "react";
import { toast } from "react-toastify";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
    mutations: {
      onError: () => {
        toast.error("Something went wrong");
      },
    },
  },
});

export function QueryClientProvider({ children }: { children: ReactNode }) {
  return (
    <_ReactQueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </_ReactQueryClientProvider>
  );
}
