import { useEffect, useState } from "react";

const useHeaderSwitch = () => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);

  useEffect(() => {
    const storedValue = localStorage.getItem("isHeaderVisible");
    if (storedValue) {
      setIsHeaderVisible(JSON.parse(storedValue));
    }
  }, []);

  const toggleHeader = () => {
    setIsHeaderVisible((state) => {
      localStorage.setItem("isHeaderVisible", JSON.stringify(!state));
      return !state;
    });
  };

  return { isHeaderVisible, toggleHeader };
};

export default useHeaderSwitch;
