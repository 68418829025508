import { registerLicense } from "@syncfusion/ej2-base";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

registerLicense(
  "ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5VdERjW3pdcHJUR2lb",
);

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
